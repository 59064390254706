import React from 'react';
import axios from 'axios'
import SwipeableViews from 'react-swipeable-views';

class MainAppContainer extends React.Component {

  constructor() {
    super();
    this.state = {
      guestID: "",
      guestPhoneNumber: "",
      invitationTo: "",
      wtpsMsg: "",
      isAttending: "--",
      nbrOfAttending: 0,
      isVideoMuted: true,
      nbrOfInvited: "",
      checkYes: false,
      checkNo: false,
      didstart: false,

      AppData: {
        husband: "",
        bride: "",
        bibleQuotes: "",
        evangelist: "",
        marriageQuotes: [],
        ceremonyTitle: "",
        ceremonyDate: "",
        ceremonyDateAR: "",
        ceremonyTime: "",
        ceremonyCurch: "",
        ceremonyCurchAR: "",
        ceremonyLocation: "",
        ceremonyGoogleMap: "",
        dinner: {
          dinnerTitle: "",
          dinnerDate: "",
          dinnerTime: "",
          dinnerResto: "",
          dinnerLocation: "",
          dinnerGoogleMap: ""
        },
        gift: {
          giftRegistryTitle: "",
          giftRegistry: "",
          giftRegistryID: ""
        },
        rsvp: {
          rsvpTitle: "",
          rsvpReplyDate: "",
          rsvpNumberOfPersons: "",
          nbrOfInvited: ""
        }
      },
      apiCall: "https://app.michelandchristine.com///v1/api/get_data",
      displayLastPage: false
    };
  }


  componentDidMount = async () => {
    let AppData

    await fetch("AppData.json").then(
      function (res) {
        return res.json()
      }).then(function (data) {
        AppData = data.data
      }).catch(
        function (err) {
          console.log(err, ' error')
        }
      )

    let tempData
    let guestID = window.location.search
    // let guestID = "?id=ghjy29xnla9d8aa"

    await axios({
      method: "get",
      url: `${this.state.apiCall + '/search' + guestID}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        tempData = response.data
      })
      .catch(error => {
        if (axios.isCancel(error)) { console.log(error.message); }
      });
    this.setState({
      AppData,
      guestID: tempData.id ? tempData.id : '',
      invitationTo: tempData.name ? tempData.name : '',
      nbrOfInvited: tempData.NumberOfGuests ? tempData.NumberOfGuests : '',
      guestPhoneNumber: tempData.mobilenumber ? tempData.mobilenumber : '',
      wtpsMsg: tempData.wtpsMsg ? tempData.wtpsMsg : '',
    })
  }

  startCardTour = () => {
    document.getElementById("SwipeableViews").style.visibility = "visible"
    document.getElementById("startPage").style.visibility = "hidden"

    this.setState({ didstart: true, isVideoMuted: false })
  }

  handleMute = () => {
    this.setState({ isVideoMuted: !this.state.isVideoMuted })
  }

  handleInput = event => {
    if (event.target.value && (event.target.value > parseInt(this.state.nbrOfInvited) || event.target.value < 0))
      this.setState({ nbrOfAttending: 0 })
    else
      this.setState({ nbrOfAttending: event.target.value })

    document.getElementById("demoInput").style.borderColor = "white"
  }

  increment = () => {
    if (parseInt(this.state.nbrOfAttending) + 1 > parseInt(this.state.nbrOfInvited))
      this.setState({ nbrOfAttending: this.state.nbrOfAttending })
    else
      this.setState({ nbrOfAttending: parseInt(this.state.nbrOfAttending) + 1 })

    document.getElementById("demoInput").style.borderColor = "white"
  }

  decrement = () => {
    if (parseInt(this.state.nbrOfAttending) - 1 < 0)
      this.setState({ nbrOfAttending: this.state.nbrOfAttending })
    else
      this.setState({ nbrOfAttending: parseInt(this.state.nbrOfAttending) - 1 })

    document.getElementById("demoInput").style.borderColor = "white"
  }

  handleSelect = event => {
    if (event.target.value === "yes")
      this.setState({ isAttending: event.target.value, nbrOfAttending: this.state.nbrOfInvited })
    else if (event.target.value === "no")
      this.setState({ isAttending: event.target.value, nbrOfAttending: 0 })
    else
      this.setState({ isAttending: "--", nbrOfAttending: 0 })

    // document.getElementById("radioAttending").style.color = "white"
    document.getElementById("demoInput").style.borderColor = "white"
  }

  reserve = async () => {
    if (this.state.isAttending === "yes" && this.state.nbrOfAttending === 0) {
      debugger
      document.getElementById("demoInput").style.borderColor = "red"
      return
    }

    let params = {
      Attending: this.state.isAttending,
      NumberOfAttendes: this.state.nbrOfAttending
    }

    await axios({
      method: "patch",
      url: `${this.state.apiCall + '/id/' + this.state.guestID}`,
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response)
          this.setState({ displayLastPage: true })
      })
      .catch(error => {
        if (axios.isCancel(error)) { console.log(error.message); }
      });

  }

  render() {
    let { AppData, isVideoMuted, nbrOfAttending, isAttending } = this.state
    return (
      <>
        <div id="swipeableViewsParentContainer" style={this.state.displayLastPage === true ? { display: "none" } : { display: "block" }}>
          <div id="leafContainer" data-image="bigLeaf" className="bigLeaf">
            <div style={{ top: "115%", left: "4271px", animationName: "fade, drop", aniationDuration: "21.5328s, 21.5328s", animationDelay: "0.649181s, 0.649181s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.2989s" }} />
            </div>
            <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
            </div>
            <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
            </div>
            <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
            </div>
            <div style={{ top: "115%", left: "2248px", animationName: "fade, drop", animationDuration: "26.1889s, 26.1889s", animationDelay: "0.078033s, 0.078033s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.4703s" }} />
            </div>
            <div style={{ top: "115%", left: "3547px", animationName: "fade, drop", animationDuration: "31.2799s, 31.2799s", animationDelay: "0.423443s, 0.423443s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "12.0255s" }} />
            </div>
            <div style={{ top: "115%", left: "205px", animationName: "fade, drop", animationDuration: "30.8377s, 30.8377s", animationDelay: "0.623964s, 0.623964s" }}>
              <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "13.0019s" }} />
            </div>
            <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
            </div>
            <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
            </div>
            <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
            </div>
            <div style={{ top: "115%", left: "43px", animationName: "fade, drop", animationDuration: "35.0827s, 35.0827s", animationDelay: "0.36684s, 0.36684s" }}>
              <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "15.6451s" }} />
            </div>
            <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
            </div>
            <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
            </div>
            <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
            </div>
            <div style={{ top: "115%", left: "2826px", animationName: "fade, drop", animationDuration: "35.7176s, 35.7176s", animationDelay: "0.142368s, 0.142368s" }}>
              <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.8249s" }} />
            </div>
            <div style={{ top: "115%", left: "621px", animationName: "fade, drop", animationDuration: "37.7397s, 37.7397s", animationDelay: "0.396265s, 0.396265s" }}>
              <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.9869s" }} />
            </div>
            <div style={{ top: "115%", left: "295px", animationName: "fade, drop", animationDuration: "23.2892s, 23.2892s", animationDelay: "0.244458s, 0.244458s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "18.1451s" }} />
            </div>
            <div style={{ top: "115%", left: "3295px", animationName: "fade, drop", animationDuration: "21.5757s, 21.5757s", animationDelay: "0.833078s, 0.833078s" }}>
              <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "15.8504s" }} />
            </div>
            <div style={{ top: "115%", left: "4014px", animationName: "fade, drop", animationDuration: "34.9891s, 34.9891s", animationDelay: "0.333657s, 0.333657s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "16.1805s" }} />
            </div>
            <div style={{ top: "115%", left: "84px", animationName: "fade, drop", animationDuration: "29.3781s, 29.3781s", animationDelay: "0.30615s, 0.30615s" }}>
              <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.7186s" }} />
            </div>
            <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
            </div>
            <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
            </div>
            <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
            </div>
            <div style={{ top: "115%", left: "863px", animationName: "fade, drop", animationDuration: "23.7322s, 23.7322s", animationDelay: "0.76869s, 0.76869s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "14.3942s" }} />
            </div>
            <div style={{ top: "115%", left: "2771px", animationName: "fade, drop", animationDuration: "21.7417s, 21.7417s", animationDelay: "0.0370709s, 0.0370709s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "15.8919s" }} />
            </div>
            <div style={{ top: "115%", left: "935px", animationName: "fade, drop", animationDuration: "38.3105s, 38.3105s", animationDelay: "0.920299s, 0.920299s" }}>
              <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "19.0806s" }} />
            </div>
            <div style={{ top: "115%", left: "3347px", animationName: "fade, drop", animationDuration: "37.37s, 37.37s", animationDelay: "0.0346564s, 0.0346564s" }}>
              <img alt="realLeaf" src="img/realLeaf3.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.9137s" }} />
            </div>
            <div style={{ top: "115%", left: "3209px", animationName: "fade, drop", animationDuration: "20.5643s, 20.5643s", animationDelay: "0.0979504s, 0.0979504s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "14.8381s" }} />
            </div>
            <div style={{ top: "115%", left: "1033px", animationName: "fade, drop", animationDuration: "32.6238s, 32.6238s", animationDelay: "0.761477s, 0.761477s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "counterclockwiseSpinAndFlip", animationDuration: "11.7593s" }} />
            </div>
            <div style={{ top: "115%", left: "4494px", animationName: "fade, drop", animationDuration: "24.3477s, 24.3477s", animationDelay: "0.44706s, 0.44706s" }}>
              <img alt="realLeaf" src="img/realLeaf2.png" style={{ animationName: "clockwiseSpin", animationDuration: "17.0681s" }} />
            </div>
            <div style={{ top: "115%", left: "1883px", animationName: "fade, drop", animationDuration: "22.9141s, 22.9141s", animationDelay: "0.144015s, 0.144015s" }}>
              <img alt="realLeaf" src="img/realLeaf4.png" style={{ animationName: "clockwiseSpin", animationDuration: "11.7241s" }} />
            </div>
            <div style={{ top: "115%", left: "1440px", animationName: "fade, drop", animationDuration: "30.387s, 30.387s", animationDelay: "0.112946s, 0.112946s" }}>
              <img alt="realLeaf" src="img/realLeaf1.png" style={{ animationName: "clockwiseSpin", animationDuration: "12.6348s" }} />
            </div>
          </div>

          {this.state.didstart &&
            <video autoPlay={true} muted={isVideoMuted} playsInline={true} loop={true} controls={false} id="preweddingvideo" >
              <source src="/video/MichoTima.mp4" type="video/mp4" />
            </video>
          }

          <div id="startPage" className='swipeablePage' >
            <div style={{ fontFamily: "Lora', serif", fontSize: "30px", marginTop: "20%" }}>Welcome</div>
            <div style={{ fontFamily: "Lora', serif", fontSize: "30px" }}>{this.state.invitationTo}</div>

            <div id="startPageBtn" onClick={this.startCardTour} >
              <div style={{ fontFamily: "'Ubuntu', sans-serif, Verdana", fontSize: "30px", marginTop: "35px" }}>Tap</div>
              <div style={{ fontFamily: "'Ubuntu', sans-serif, Verdana", fontSize: "30px" }}>to</div>
              <div style={{ fontFamily: "'Ubuntu', sans-serif, Verdana", fontSize: "30px" }}>Start</div>
            </div>
          </div>

          <SwipeableViews enableMouseEvents id="SwipeableViews">
            <div id="slide1" className='swipeablePage'>
              <div id="slide1Header">
              <div style={{  paddingTop: "25px" }} >{AppData.husband}</div>
                <div>&</div>
                <div>{AppData.bride}</div>  
              </div>
              <div id="slide1Body">
                {/* <div>{AppData.husband}</div>
                <div>&</div>
                <div>{AppData.bride}</div> */}
                {/* <div style={{ fontFamily: "'Lora', serif", fontSize: "20px" }}>Are Getting Married</div> */}
              </div>
              <div id="slide1Footer">
                <div id="muteUnmute" onClick={this.handleMute}>
                  {
                    isVideoMuted ?
                      <>&#128263;</>
                      :
                      <>&#128264;</>
                  }
                </div>
                <div className="arrow">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}>SWIPE LEFT</div>
              </div>
            </div>

            <div id="slide2" className='swipeablePage'>
              <div id="slide2Header">
              </div>
              <div id="slide2Body">
                <div style={{ fontFamily: "'Lora', serif", fontSize: "18px", padding: "0 10px" }}>{AppData.bibleQuotes}</div>
                <div style={{ fontFamily: "'Lora', serif", fontSize: "16px", margin: "20px 0", fontStyle: "italic" }}>{AppData.evangelist}</div>
                <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", margin: "50px 0", padding: "0 20px" }}>
                  {AppData.marriageQuotes.map((eachElem, key) => {
                    if (eachElem === "")
                      return <br />
                    else
                      return <div key={key}>{eachElem}</div>
                  })}
                </div>
                {/* <div style={{ fontFamily: "'Great Vibes', cursive", fontSize: "38px", margin: "20px 0", }} >{AppData.husband}&nbsp;&nbsp;&&nbsp;&nbsp;{AppData.bride}</div> */}
                <div style={{ fontFamily: "'Lora', serif", fontSize: "20px" }}>On Saturday {AppData.ceremonyDate}</div>
                <div style={{ fontFamily: "'Lora', serif", fontSize: "20px" }}>وذلك نهار السّبت الواقع فيه {AppData.ceremonyDateAR}</div>
 
              </div>
              <div id="slide2Footer">
                <div id="muteUnmute" onClick={this.handleMute}>
                  {
                    isVideoMuted ?
                      <>&#128263;</>
                      :
                      <>&#128264;</>
                  }
                </div>
                <div className="arrow">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}>SWIPE LEFT</div>
              </div>
            </div>

            <div id="slide3" className='swipeablePage'>
              <div id="slide3Header">
                <div style={{ paddingTop: "20px" }}>{AppData.ceremonyTitle}</div>
              </div>
              <div id="slide3Body">
                <div >
                  <div style={{ height: "50px" }} />
                  {/* <div style={{ fontSize: "35px", }}>&#128340;</div> */}
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "55px", fontWeight: "bold" }}>{AppData.ceremonyDate}</div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px", fontWeight: "bold" }}>{AppData.ceremonyDateAR}</div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px" }}>{AppData.ceremonyTime}</div>
                </div>

                <div >
                  <div style={{ fontSize: "35px", marginTop: "50px" }}>	&#128205; </div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px", fontWeight: "bold" }}>{AppData.ceremonyCurch}</div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px", fontWeight: "bold" }}>{AppData.ceremonyCurchAR}</div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px" }}>{AppData.ceremonyLocation}</div>
                </div>
                <div style={{ margin: "20px" }}>
                  <div style={{ margin: "20px" }}>
                    <div style={{ padding: "10px", border: "#fff solid 1px", width: "100px", borderRadius: "10px", margin: "auto" }}><a href={AppData.ceremonyGoogleMap} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>Location Map</a></div>
                  </div>
                </div>



              </div>
              <div id="slide3Footer">
                <div id="muteUnmute" onClick={this.handleMute}>
                  {
                    isVideoMuted ?
                      <>&#128263;</>
                      :
                      <>&#128264;</>
                  }
                </div>
                <div className="arrow">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}>SWIPE LEFT</div>
              </div>
            </div>

            {AppData.dinner && AppData.dinner.dinnerTitle &&
              <div id="slide4" className='swipeablePage'>
                <div id="slide4Header">
                  <div style={{ paddingTop: "20px" }}>{AppData.dinner.dinnerTitle}</div>
                </div>
                <div id="slide4Body">
                  <div >
                    <div style={{ height: "50px" }} />
                    {/* <div style={{ fontSize: "35px" }}>&#128342;</div> */}
                    <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "40px", fontWeight: "bold" }}>{AppData.dinner.dinnerDate}</div>
                    <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px" }}>{AppData.dinner.dinnerTime}</div>
                  </div>

                  <div >
                    <div style={{ fontSize: "35px", marginTop: "50px" }}>	&#128205;</div>
                    <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px", fontWeight: "bold" }}>{AppData.dinner.dinnerResto}</div>
                    <div style={{ fontFamily: "'Lora', serif", fontSize: "20px", marginTop: "10px" }}>{AppData.dinner.dinnerLocation}</div>
                  </div>

                  <div style={{ margin: "20px" }}>
                    <div style={{ padding: "10px", border: "#fff solid 1px", width: "100px", borderRadius: "10px", margin: "auto" }}><a href={AppData.dinner.dinnerGoogleMap} target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>Location Map</a></div>
                  </div>
                </div>
                <div id="slide4Footer">
                  <div id="muteUnmute" onClick={this.handleMute}>
                    {
                      isVideoMuted ?
                        <>&#128263;</>
                        :
                        <>&#128263;</>
                    }
                  </div>
                  <div className="arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>

                  <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}>SWIPE LEFT</div>
                </div>
              </div>
            }

            {AppData.gift && AppData.gift.giftRegistryTitle &&
              <div id="slide5" className='swipeablePage'>
                <div id="slide5Header">
                  <div style={{ paddingTop: "20px" }}>{AppData.gift.giftRegistryTitle}</div>
                </div>
                <div id="slide5Body">
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "21px", padding: "10px 15px 3px 15px" }}>لمن يرغب، لائحة الهدايا لدى </div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "21px" }}>'Whish'</div>
                  <div style={{ backgroundImage: "url(/img/image01.png)", backgroundSize: "contain", backgroundRepeat: "no-repeat", height: "35px", width: "100px", marginLeft: "calc(50% - 50px)" }} />
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "21px"}}>بكافّة فروعه </div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "23px" }}>Account ID:{AppData.gift.giftRegistryID}</div>
                </div>
                <div id="slide5Footer">
                  <div id="muteUnmute" onClick={this.handleMute}>
                    {
                      isVideoMuted ?
                        <>&#128263;</>
                        :
                        <>&#128263;</>
                    }
                  </div>
                  <div className="arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}>SWIPE LEFT</div>
                </div>
              </div>
            }

            {AppData.rsvp && AppData.rsvp.rsvpTitle &&
              <div id="slide6" className='swipeablePage'>
                <div id="slide6Header">
                  <div style={{ paddingTop: "20px" }}>{AppData.rsvp.rsvpTitle}</div>
                </div>
                <div id="slide6Body">
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "22px", padding: "60px 15px 30px 15px" }}>{AppData.rsvp.rsvpReplyDate}</div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "16px", fontStyle: "italic" }}>الدّعوة ل:&nbsp;&nbsp;{this.state.nbrOfInvited}</div>

                  <select id="attendingSelector" className='attendingPart' name="attendingSelector" style={{ marginTop: "50px" }} onChange={this.handleSelect}>
                    <option value="--">--Are You Attending?--</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>

                  <div style={{ marginTop: "30px" }}>
                    <button className='attendingPart' style={{ width: "40px" }} onClick={this.decrement} disabled={isAttending === "yes" ? false : true}>-</button>
                    <input id="demoInput" className='attendingPart' type="number" style={{ width: "150px", margin: "0 10px" }} value={nbrOfAttending} min={0} max={2}
                      disabled={isAttending === "yes" ? false : true}
                      onChange={this.handleInput}></input>
                    <button className='attendingPart' style={{ width: "40px" }} onClick={this.increment} disabled={isAttending === "yes" ? false : true} >+</button>
                  </div>

                  <button className='attendingPart' style={{ marginTop: "30px" }} onClick={this.reserve} disabled={isAttending === "yes" || isAttending === "no" ? false : true}>RSVP</button>
                </div>
                {/* <div id="slide6Footer">
                  <div id="muteUnmute" onClick={this.handleMute}>
                    {
                      isVideoMuted ?
                        <>&#128263;</>
                        :
                        <>&#128263;</>
                    }
                  </div>
                  <div className="arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}>SWIPE LEFT</div>

                </div> */}
              </div>
            }
            {/* {AppData.thanksQuote &&
              <div id="slide7" className='swipeablePage'>
                <div id="slide7Header">
                </div>
                <div id="slide7Body">
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "21px", padding: "60px 15px 30px 15px" }}>{AppData.thanksQuote}</div>
                </div>
                <div id="slide7Footer">
                  <div id="muteUnmute" onClick={this.handleMute}>
                    {
                      isVideoMuted ?
                        <>&#128263;</>
                        :
                        <>&#128263;</>
                    }
                  </div>
                  <div style={{ fontFamily: "'Lora', serif", fontSize: "11px" }}></div>
                </div>
              </div>
            } */}
          </SwipeableViews>

        </div>
        <div id='lastPage' style={this.state.displayLastPage === true ? { display: "block", height: "100vh", opacity: 0.7, backgroundColor: "black" } : { display: "none" }}>
          <div style={{ color: "white" }}>
            <div style={{ height: "100px" }}></div>
            <div style={{ backgroundImage: "url(/img/LogoMC.jpeg)", backgroundSize: "contain", backgroundRepeat: "no-repeat", height: "200px", width: "200px", filter: "brightness(0) invert(1)", marginLeft: "calc(50% - 100px)" }} />
            {/* <div style={{ fontFamily: "'Lora', serif", fontSize: "21px", padding: "60px 15px 30px 15px", color: "white" }}>{AppData.thanksQuote}</div> */}
            
          </div>
        </div>
      </>
    );
  }
}

export default MainAppContainer;