import './App.css';
import MainAppContainer from './MainAppContainer';

function App() {
  return (
    <MainAppContainer id="MainAppContainer" />
  );
}

export default App;
